@use '../../../assets/styles/vendor/material-icons';
@use '../../../../../wen-components/src' as wen-components;
@use "../../../lib/frame/components/header/components/mobile-header/mobile-header.theme";
@use "../../../lib/frame/components/wen-widget/wen-widget.theme";
@use "../../../lib/views/channel/channel-view/components/channel-content-curtain/channel-restriction/channel-restriction.theme";
@use "../../../lib/views/apps/app-detail/components/app-detail/app-detail.theme";
@use "../../../lib/shared/components/expandable-container/expandable-container.theme";
@use "../../../lib/views/events/event-list/components/user-event-list/user-event-list.theme";
@use "../../../lib/views/home/components/home/home.theme";
@use "../../../lib/views/coming-soon-page/coming-soon.theme";
@use "../../../lib/views/apps/app-list/components/discover-app-list/discover-app-list.theme";
@use "../../../lib/views/events/event-list/components/discovery-event-list/discovery-event-list.theme";
@use "../../../lib/views/apps/app-list/components/user-app-list/user-app-list.theme";
@use "../../../lib/shared/components/empty-list/empty-list.theme";
@use "../../../lib/shared/components/image-upload/image-upload.theme";
@use "../../../lib/views/channel/channel-list/components/user-channel-list/user-channel-list.theme";
@use "../../views/channel/channel-subscriber-list/channel-subscriber-list.theme";
@use "../../frame/components/navigation-bar/navigation-bar.theme";
@use "../../frame/components/header/components/app-header/app-header.theme";
@use "../../views/channel/channel-list/components/channel-list/channel-list.theme";
@use "../../views/user-profile/user-profile-view/components/user-profile-view/user-profile-view.theme";
@use "../../views/onboarding/onboarding-start/components/onboarding-start/onboarding-start.theme";
@use "../../views/user-profile/profile-form/components/profile-read/profile-read-view.theme";
@use "../../views/user-profile/user-credentials/components/user-credentials.theme";
@use "../../views/user-profile/profile-form/components/other-profile/other-profile-view.theme";
@use "../../views/channel/channel-admin-list/components/channel-admin-list/channel-admin-list.theme";
@use "../../views/channel/channel-view/components/channel-view/channel-view.theme";
@use "../../views/chat/chat-view/chat-view.theme";
@use "../../views/channel/channel-schedules/components/channel-schedules/channel-schedules.theme";
@use "../../views/chat/chat-schedules/components/chat-schedules-view/chat-schedules-view.theme";
@use "../../views/user-profile/email-settings/components/email-settings/email-settings.theme";
@use "../../views/channel/channel-detail/components/channel-detail/channel-detail.theme";
@use "../../views/events/event-detail/components/event-detail/event-detail.theme";
@use "../../views/channel/channel-featured-view/channel-featured-view.theme";
@use "../../views/channel/channel-list/components/discover-channel-list/discover-channel-list.theme";
@use "../../views/channel/channel-detail/components/channel-settings/components/personal-weather/personal-weather.theme";
@use "../../shared/components/rounded-button/rounded-button.theme";
@use "../../shared/components/create-welcome/create-welcome.theme";
@use "../../shared/components/notification-count/notification-count.theme";
@use "../../shared/components/search-bar/search-bar.theme";
@use "../../shared/components/list-item/list-item.theme";
@use "../../shared/components/detail-header/detail-header.theme";
@use "../../shared/components/message-box/components/embeds/embed-expandable/embed-expandable.theme";
@use "../../shared/components/message-box/components/embeds/embed-notification/embed-notification.theme";
@use "../../shared/components/message-box/message-box.theme";
@use "../../shared/components/message-send-field/components/message-scheduler/time-picker/time-picker.theme";
@use '../../shared/components/user-list-item/user-list-item.theme';
@use "../../views/chat/chat-list/components/user-chat-list/user-chat-list.theme";
@use "../../views/chat/chat-detail/components/chat-detail-user-list/chat-detail-user-list.theme";
@use "../../views/chat/chat-creation/chat-creation.theme";
@use '../../shared/components/create-welcome/create-welcome-markdown/create-welcome-markdown.theme';
@use './lazyload-styling';
@use './crop-dialog-styling';
@use './form-styling';
@use './form.theme';
@use './form-field-styling';
@use './form-field.theme';
@use './material-date-picker.theme';
@forward './mixins';
@forward './color-modes';

@mixin all-themes($theme, $typography) {
  @include material-icons.add-material-icons();
  @include navigation-bar.theme($theme, $typography, wen-components.$var-navBarConfig);
  @include channel-view.theme($theme, $typography);
  @include channel-list.theme($theme, $typography);
  @include rounded-button.theme($theme, $typography);
  @include notification-count.theme($theme, $typography, wen-components.$var-notificationConfig);
  @include search-bar.theme($theme, $typography);
  @include user-profile-view.theme($theme, $typography);
  @include list-item.theme($theme, $typography);
  @include message-box.theme($theme);
  @include app-header.theme($theme);
  @include embed-expandable.theme($theme);
  @include embed-notification.theme($theme, $typography);
  @include event-detail.theme($theme);
  @include email-settings.theme($theme);
  @include profile-read-view.theme($theme);
  @include user-credentials.theme($theme);
  @include other-profile-view.theme($theme);
  @include channel-detail.theme($theme);
  @include mobile-header.theme($theme);
  @include app-detail.theme($theme);
  @include user-app-list.theme($theme);
  @include channel-restriction.theme($theme);
  @include user-event-list.theme($theme);
  @include coming-soon.theme($theme);
  @include channel-featured-view.theme($theme);
  @include channel-admin-list.theme($theme);
  @include create-welcome.theme($theme);
  @include discover-channel-list.theme($theme);
  @include home.theme($theme, $typography);
  @include image-upload.theme($theme);
  @include expandable-container.theme($theme);
  @include discovery-event-list.theme($theme);
  @include discover-app-list.theme($theme);
  @include user-channel-list.theme($theme);
  @include empty-list.theme($theme);
  @include detail-header.theme($theme, $typography);
  @include wen-widget.theme($theme);
  @include onboarding-start.theme($theme, $typography);
  @include lazyload-styling.add-lazyload-styling();
  @include crop-dialog-styling.add-crop-dialog-styling();
  @include form-styling.add-form-styling();
  @include form-field-styling.add-form-field-styling();
  @include form-field.theme();
  @include form.theme($theme);
  @include time-picker.theme($theme);
  @include material-date-picker.theme($typography);
  @include user-chat-list.theme($theme, $typography);
  @include channel-schedules.theme($theme);
  @include chat-schedules-view.theme($theme);
  @include chat-view.theme($theme, $typography);
  @include user-list-item.theme($typography);
  @include channel-subscriber-list.theme($theme, $typography);
  @include chat-detail-user-list.theme($theme, $typography);
  @include chat-creation.theme($theme);
  @include create-welcome-markdown.theme($typography);
  @include personal-weather.theme($theme);


  [with-colon]:after {
    content: '\003A\00a0' ;
  }
  [in-brackets] {
    &:before {
      content: '\0028'
    }
    &:after {
      content: '\0029'
    }
  }
  [with-pipe-before] {
    &:before {
      content: '\007C\00a0'
    }
  }
}
