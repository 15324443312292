@use "sass:map";

@mixin theme($theme) {
  $background-map: map.get($theme, "background");
  $default-background: map.get($background-map, "default-background");
  
  wen-time-picker {
    background-color: $default-background;
  }
}
