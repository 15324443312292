@use "@portal/ui-kit";
@use "sass:map";
@use "../../../core/theming/variables";

@mixin _add-button-styles($background, $text-color, $icon-color, $disabled-background, $disabled-text-color, $border-color) {
  background-color: $background;

  @if ($border-color) {
    border: 1px solid $border-color;
  }

  wen-icon {
    color: $icon-color;
  }

  .wen-button-text-content {
    color: $text-color;
  }

  &.wen-button-disabled {
    background-color: $disabled-background;
    wen-icon {
      color: $disabled-text-color;
    }

    .wen-button-text-content {
      color: $disabled-text-color;
    }
  }
}

@mixin theme($theme) {
  $primaryColor: ui-kit.get-color-from-palette(map.get($theme, primary));
  wen-button {
    &.wen-button-variant-icon-text {
      @include _add-button-styles(transparent, variables.$grey8, variables.$grey7, transparent, variables.$grey5, null);
    }
    &.wen-button-variant-icon {
      @include _add-button-styles(transparent, null, variables.$grey8, transparent, variables.$grey5, null);
    }
    &.wen-button-variant-icon-primary {
      @include _add-button-styles($primaryColor, null, white, variables.$grey5, null, null);
    }
    &.wen-button-variant-primary {
      @include _add-button-styles($primaryColor, white, white, variables.$grey5, white, null);
    }

    &.wen-button-variant-secondary {
      @include _add-button-styles(transparent, $primaryColor, $primaryColor, transparent, variables.$grey5, $primaryColor);
    }

    &.wen-button-variant-primary-background {
      @include _add-button-styles(transparent, $primaryColor, white, variables.$grey5, white, null);
    }
  }
}
