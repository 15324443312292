@use 'sass:map';
@use '@portal/ui-kit';
@use '../../../../../../wen-components/src' as wen-components;

@mixin theme($theme, $typography) {
  $primary: map.get($theme, primary);

  .wen-rounded-button {
    &:not(.wen-rounded-button-transparent) {
      .wen-rounded-button-button {
        background-color: ui-kit.get-color-from-palette($primary);
        color: white;
      }
    }
    &.wen-rounded-button-transparent {
      .wen-rounded-button-button {
        background-color: transparent;
        color: ui-kit.get-color-from-palette($primary);
      }
    }
    &.wen-rounded-button-border {
      .wen-rounded-button-button {
        border: 1px solid ui-kit.get-color-from-palette($primary);
      }
    }
    &:not(.wen-rounded-button-border) {
      .wen-rounded-button-button {
        border: none;
      }
    }
    &.wen-rounded-button-background {
      .wen-rounded-button-button {
        background-color: transparent;
        color: wen-components.$var-grey7;
      }
    }
    &.wen-rounded-button-disabled {
      .wen-rounded-button-button {
        background-color: wen-components.$var-grey5;
      }
    }
  }
}
