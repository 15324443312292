@use 'sass:map';
@use '../../../../../../wen-components/src' as wen-components;

@mixin theme($theme) {
  $background-map: map.get($theme, 'background');
  $default-background: map.get($background-map, 'default-background');
  $text-color-dark-grey: map.get($background-map, 'text-color-dark-grey');

  .wen-message-box {
    --wen-messagebox-background-color: #{$default-background};
    --wen-messagebox-text-color: #{$text-color-dark-grey};
    --wen-messagebox-text-color-light: #{$text-color-dark-grey};
    --wen-messagebox-link-color: var(--wen-link-color);
    --wen-messagebox-error-color: var(--wen-error-color);

    --wen-embed-background-color: #{wen-components.$var-white2};
    --wen-embed-text-color: #{wen-components.$var-grey6};
    --wen-embed-text-color-light: #{wen-components.$var-grey4};

    --wen-messagebox-background-color-highlighted: #{$default-background};
    --wen-messagebox-text-color-highlighted: #{$text-color-dark-grey};
    --wen-messagebox-text-color-light-highlighted: #{$text-color-dark-grey};
    --wen-messagebox-link-color-highlighted: var(--wen-link-color);
    --wen-messagebox-error-color-highlighted: var(--wen-error-color);

    --wen-embed-background-color-highlighted: #{wen-components.$var-white2};
    --wen-embed-text-color-highlighted: #{wen-components.$var-grey6};
    --wen-embed-text-color-light-highlighted: #{wen-components.$var-grey4};

    &.wen-message-box-highlighted .wen-message-wrapper {
      --wen-messagebox-background-color: var(--wen-messagebox-background-color-highlighted);
      --wen-messagebox-text-color: var(--wen-messagebox-text-color-highlighted);
      --wen-messagebox-text-color-light: var(--wen-messagebox-text-color-light-highlighted);
      --wen-messagebox-link-color: var(--wen-messagebox-link-color-highlighted);
      --wen-messagebox-error-color: var(--wen-messagebox-error-color-highlighted);

      --wen-embed-background-color: var(--wen-embed-background-color-highlighted);
      --wen-embed-text-color: var(--wen-embed-text-color-highlighted);
      --wen-embed-text-color-light: var(--wen-embed-text-color-light-highlighted);
    }

    transition: filter 300ms ease-in-out;
  }

  .wen-message-box-wrapper {
    background: var(--wen-messagebox-background-color);
    color: var(--wen-messagebox-text-color);
  }

  .wen-message-box-highlight {
    filter: brightness(50%);
  }
}