@use 'sass:map';
@use '@portal/ui-kit';

@mixin theme($theme) {
  $primary: map.get($theme, primary);
  $primary-color: ui-kit.get-color-from-palette($primary);
  
  mat-checkbox {
    &.cursor-initial {
      .mdc-checkbox {
        cursor: initial;
      }
    }
  }

  .mdc-checkbox__ripple {
    display: none; 
  }

  mat-checkbox:not(.borderless-check) {

    &.mat-mdc-checkbox-checked {
      .mdc-checkbox__background {

        svg {
          transform: scale(0.8);
        }
      }
    }
  }
}
